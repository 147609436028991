@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slick-slider {
  width: 100%;
}

.normalImg {
  max-width: 100%;
  height: auto;
}

.App {
  height: 100%;
  color: white;

  font-family: "Mona Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  overflow: hidden;
}

.top-section {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
}

.body {
  position: absolute;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  /* height: 100%; */
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.imgAndDescription {
  display: flex;
  justify-content: center;
}

.imageDescriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  margin-top: 10px;
  gap: 10px;
  margin-bottom: 32px;
}

.imgDescription {
  margin-top: 5px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 800px;
  width: max-content;
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: rgb(230, 237, 243);
}

@media (max-width: 544px) {
  .imgDescription {
    width: 100%; /* Default width is 100% of the parent container */
    max-width: max-content;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
  }
}

.iglLogoText {
  font-size: 16px;
  line-height: 24px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #121416, transparent 300px),
    linear-gradient(90deg, #00dbde 0%, #7a007b 100%);
  opacity: 0.05;
  pointer-events: none;
  z-index: -1;
}

.logoContainer {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-top: 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.arrow {
  position: absolute;
  top: 100px;
  cursor: pointer;
}

.nextArrow {
  right: -35px;
  transform: rotate(-90deg);
}

.prevArrow {
  left: -35px;
  transform: rotate(90deg);
}
.nextArrow:hover svg {
  animation: shiftArrow 0.4s ease-in-out forwards;
}
.prevArrow:hover svg {
  animation: shiftArrow 0.4s ease-in-out forwards;
}

@keyframes shiftArrow {
  50% {
    transform: translateY(6px);
  }
}

.imgContainer {
  display: flex;
  width: calc(100% - 30px);
}

.normalImg {
  width: 100%;
  border-radius: 10px;
  aspect-ratio: 1804/1236;
}

@media (min-width: 544px) {
  .imgContainer {
    width: 450px;
  }
  .arrow {
    top: 150px;
  }
}

@media (min-width: 768px) {
  .imgContainer {
    width: 700px;
  }
  .arrow {
    top: 250px;
  }
}

@media (min-width: 1012px) {
  .imgContainer {
    width: 800px;
  }
  .arrow {
    top: 300px;
  }
}

.logo {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.smallImg {
  width: 40%;
  max-width: 300px;
}

h1 {
  font-size: 40px;
  line-height: 44px;
  font-weight: 800;
  text-align: start;
  margin-block-start: 0;
}

@media (min-width: 768px) {
  h1 {
    font-size: 56px;
    line-height: 60px;
  }
}

@media (min-width: 1012px) {
  h1 {
    font-size: 72px;
    line-height: 76px;
  }
}

.app-header-contents {
  margin-right: auto;
  margin-left: auto;
  padding: 24px 16px 8px 16px;
}

.headerText {
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .app-header-contents {
    width: 83.33%;
  }
}

@media (min-width: 1012px) {
  .app-header-contents {
    width: 50%;
  }
}

.App-header {
  min-height: fit-content;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.subHeader {
  color: #6e7681;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400px;
  margin-bottom: 32px;
}

.ctaContainer {
  width: fit-content;
  margin: 0 auto; /* centers the container */
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Remove full-width from the install container so it only takes the width of its content */
.installContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Make the install button fill the container */
.installButton {
  width: 100%;
  padding: 16px 80px 20px !important;
  font-size: 1.25rem;
  position: relative;
  z-index: 1;
  display: inline-block;
  font-weight: 600;
  line-height: 1;
  color: #0d1117;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #f6f8fa;
  border: 0;
  border-radius: 0.375rem;
  transition: box-shadow 0.2s, outline 0.2s ease;
  appearance: none;
}

/* Optionally, ensure the badges section is also sized to its content */
.badges {
  flex-wrap: wrap;
  display: flex;
  gap: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: baseline;
}

.installContainer {
  display: flex;
  justify-content: center;
}

@media (min-width: 1012px) {
  .subHeader {
    font-size: 24px;
    line-height: 32px;
    font-weight: 450;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
